.account_main {
    display: flex;
    flex-wrap: wrap;
    max-height: auto;
    justify-content: center;
    margin: 2rem 2rem 2rem 2rem;
    background-color: #ffffff;
}

.account_main:hover {
    transform: translateY(-0.1rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}

.account_box_left {
    background-color: #fff;
    flex: 1;
    max-width: 40rem;
    max-height: auto;
    transition: 0.2s;
    margin-right: 1rem;
    padding: 3rem 0rem 0rem 3rem;
}

.account_box_right {
    position: relative;
    background-color: #fff;
    flex: 1;
    max-width: 40rem;
    max-height: auto;
    transition: 0.2s;
    margin-left: 1rem;
}

.logoTitle {
    display: flex;
    flex-wrap: wrap;
    max-height: auto;
    align-items: center;
}

.logo {
    vertical-align: middle;
    width: 5rem;
}

.account_image {
    margin: 2rem 2rem;
    vertical-align: middle;
    justify-content: center;
    width: 35rem;
}

.button {
    margin-top: 30px;
    width: 15rem;
    background-color: #00C1FF;
    color: #ffffff;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    cursor: pointer;
}

.loader {
    flex: 1;
    margin: auto;
    margin-top: 2rem;
    border: 3px solid #f3f3f3;
    /* Light grey */
    border-top: 3px solid #1cb495;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

.backGround {
    flex: 1;
    background-color: #000;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    margin: auto;
}

.textTitle{
    font-size:2rem;
    font-weight: 600;
    margin-left: 1rem;
}

.textSubTitle {
    color: gray;
}

.textSuccess {
    color: #1cb495;
    font-size: 10;
    font-weight: 800;
}

.textError {
    color: red;
    font-size: 10;
    font-weight: 500;
}

input {
    color: rgba(255, 255, 255, 0.75);
    font-size: 14pt;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1.65em;
    margin: 2rem 0rem 2rem 0;
}

input[type="text"],
input[type="number"],
input[type="email"],
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -moz-transition: border-color 0.2s ease, background-color 0.2s ease;
    -webkit-transition: border-color 0.2s ease, background-color 0.2s ease;
    -ms-transition: border-color 0.2s ease, background-color 0.2s ease;
    transition: border-color 0.2s ease, background-color 0.2s ease;
    background-color: transparent;
    border-radius: 6px;
    border: none;
    border: solid 2px #00C1FF;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 60%;
}

input[type="text"]:invalid,
input[type="number"]:invalid,
input[type="email"]:invalid,
select:invalid,
textarea:invalid {
    box-shadow: none;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
    -moz-animation: focus 0.1s;
    -webkit-animation: focus 0.1s;
    -ms-animation: focus 0.1s;
    animation: focus 0.1s;
    background-color: rgba(255, 255, 255, 0.125);
    border-color: #1cb495;
}

input[type="button"],
button,
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    background-color: #00C1FF;
    border-radius: 6px;
    border: 0;
    color: #ffffff !important;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    height: 2.75em;
    line-height: 2.75em;
    padding: 0 1.125em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

input[type="button"]:hover,
button:hover,
.button:hover {
    background-color: #1fcaa7;
}

input[type="button"]:active,
button:active,
.button:active {
    background-color: #199e83;
}


@media screen and (max-width: 480px) {

    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
        padding: 0;
    }

}

@media screen and (max-width: 825px) {

    .textTitle{
        font-size:1.5rem;
    }

    .account_box_left {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .account_image {
        width: 25rem;
    }
}